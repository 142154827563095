import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../../components";
import locales from "../../../constants";

const Aqwa = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 0,
  };
  const lang = "en";
  return (
    <Layout
      seo={{
        title: "AQWA",
        href: slug,
        lang: "en",
        ogImage: require("../../../assets/img/portfolio/aqwa-og-image.jpg"),
      }}
      header={{
        background:
          "linear-gradient( 225deg, #578ec0, #4586c0, #1d64a4, #3578bd, #2162a4 )",
        icons: "#f29229",
        navClass: "aqwa",
        ogImage: require("../../../assets/img/portfolio/aqwa_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/projekty/aqwa/",
      }}
    >
      <PortfolioHeader name="aqwa" height="214" />
      <section className="container-fluid aqwa-section-1" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>AQWA</h1>
              <ul>
                <li>Website</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner inner--divider">
              <p>
                The Norwegian company AQWA, which provides services and products
                related to water purification, approached us with a task of
                developing its new company's website. We have designed and
                developed a new website in accordance with the client's needs
                and adjusted the project to the requirements of the Norwegian
                market.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid aqwa-section-2">
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="scroll_wrapper scroll_wrapper--monitor">
              <div className="img_wrapper">
                <img
                  src={require("../../../assets/img/portfolio/aqwa_full.jpg")}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid no-gutters aqwa-section-3">
        <div className="row no-gutters">
          <div className="col-md-6 img-col">
            <img
              src={require("../../../assets/img/portfolio/aqwa_img_1.png")}
              alt=""
              className="img-fluid aqwa-img-1"
            />
          </div>
          <div className="col-md-6 img-col">
            <img
              src={require("../../../assets/img/portfolio/aqwa_img_2.png")}
              alt=""
              className="img-fluid aqwa-img-2"
            />
          </div>
        </div>
      </section>

      <section className="container-fluid aqwa-section-4">
        <div className="row">
          <div className="col-md-6 offset-md-5">
            <img
              src={require("../../../assets/img/portfolio/aqwa_img_3.png")}
              alt=""
              className="img-fluid aqwa-img-3"
            />
          </div>
        </div>
      </section>

      <section className="container-fluid no-gutters aqwa-section-5">
        <div className="row no-gutters">
          <div className="col-md-5">
            <img
              src={require("../../../assets/img/portfolio/aqwa_img_4.png")}
              alt=""
              className="img-fluid aqwa-img-4"
            />
          </div>
        </div>
      </section>

      <section className="container-fluid aqwa-section-6">
        <div className="row">
          <div className="col-md-5 offset-md-7">
            <img
              src={require("../../../assets/img/portfolio/aqwa_img_5.png")}
              alt=""
              className="img-fluid aqwa-img-5"
            />
          </div>
        </div>
      </section>

      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default Aqwa;
